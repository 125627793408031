@font-face {
  font-family: 'Droid Sans';
  src: url("646571344679ea19aa0c3f18f3151d2f.woff2") format('woff2'), url("b9f247c799be3c61d64b44e81bb2cdf0.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Droid Sans';
  src: url("3681165e92cf1c3945312a825f5c4f3e.woff2") format('woff2'), url("fce7a186b31dc620edf92fc4783c5dcc.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
:global html {
  box-sizing: border-box;
}
:global *,
:global *:before,
:global *:after {
  box-sizing: inherit;
}
:global html,
:global body,
:global .app-container {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: 'Droid Sans', Arial, Helvetica, sans-serif;
  line-height: 1.375;
  color: #000;
}
@media screen {
  :global html,
  :global body,
  :global .app-container {
    height: 100%;
  }
}
:global button:focus {
  outline: none;
}
:global .app-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
@media print {
  :global .app-container {
    display: block;
    height: 100%;
    width: 100%;
    overflow: visible;
  }
}
@media screen and (min-width: 700px) {
  :global .app-container {
    flex-direction: row;
  }
}
:global p {
  margin: 0.5em 0 1.25em 0;
}
:global p.intro {
  margin: 0 0 1em 0;
}
:global a {
  transition: color 0.25s ease-out;
}
:global img {
  max-width: 100%;
}
:global h1 {
  font-size: 1.428571428571429rem;
  font-weight: normal;
  margin: 0;
}
:global h2 {
  font-size: 1.142857142857143rem;
  font-weight: normal;
  margin: 0;
}
:global h3 {
  font-size: 0.857142857142857rem;
  font-weight: normal;
  color: #808080;
  text-transform: uppercase;
  margin: 0;
}
:global h4 {
  margin-top: 0;
  font-weight: 400;
  font-style: italic;
}
:global label {
  font-size: 0.857142857142857rem;
  height: 2.666666666666667em;
  line-height: 2.666666666666667em;
  display: inline-block;
  color: #808080;
}
:global input {
  font-size: 1rem;
  background: #fff;
  border: none;
  height: 2.285714285714286em;
  line-height: 2.285714285714286em;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
}
:global input[disabled] {
  background: #e6e6e6;
}
:global .map {
  height: 100%;
}
:global .input {
  display: flex;
  width: 100%;
}
:global .input input {
  width: 50%;
}
:global .input label {
  font-size: 0.714285714285714rem;
  height: 3.2em;
  line-height: 3.2em;
  background: #fff;
  padding: 0 1em;
  flex-shrink: 0;
  flex-grow: 0;
}
:global .input label[data-disabled='true'] {
  background: #e6e6e6;
}
:global table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0px 0px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
}
:global tbody {
  display: table-row-group;
}
:global tr {
  height: 56px;
  color: inherit;
  display: table-row;
  outline: none;
  vertical-align: middle;
}
:global th,
:global td {
  text-align: right;
  display: table-cell;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: inherit;
}
:global th:first-child,
:global td:first-child {
  text-align: left;
}
:global thead th,
:global thead td {
  color: rgba(0,0,0,0.54);
  font-weight: 500;
}
:global tbody th,
:global tbody td {
  color: rgba(0,0,0,0.87);
  font-weight: 400;
}
:global .flex-container {
  display: flex;
}
:global .justify-content-between {
  justify-content: space-between;
}
:global .icon {
  font-size: 1rem;
  visibility: visible;
  fill: #346e9f;
  width: 1em;
  height: 1em;
  transition: fill 0.25s ease-out;
  vertical-align: middle;
}
:global .assumption-intro {
  font-style: italic;
  font-weight: bold;
}
:global .assumption-input {
  text-align: right;
  background: #f2f2f2;
}
:global .assumption-non-input {
  padding-right: 25px;
}
:global .table__hint {
  font-size: 0.8rem;
}
:global .rv-xy-plot {
  font-size: 1rem;
  position: relative;
}
:global .rv-xy-plot:not(:first-child) {
  margin-top: 1.428571428571429em;
}
:global .rv-xy-plot__grid-lines__line {
  stroke: #f2f2f2;
  stroke-width: 1px;
}
:global .rv-xy-plot__grid-lines__line:first-of-type {
  stroke: #ccc;
}
:global .rv-xy-plot__series--line {
  fill: none;
  stroke-width: 0.714285714285714em;
  stroke-linecap: round;
}
.visualization--area-usage :global .rv-xy-plot__series--bar rect:first-of-type {
  transform: scaleY(0.75) translateY(3.571428571428572em);
}
.visualization--area-usage :global .rv-xy-plot__series--bar rect:last-of-type {
  transform: scaleY(0.75) translateY(1.428571428571429em);
}
.rv-radial-chart :global .rv-xy-plot__series--label-text {
  font-size: 1rem;
}
:global .rv-xy-plot__axis__title {
  font-size: 0.857142857142857em;
  font-family: 'Droid Sans', sans-serif;
  fill: #808080;
  transform: translate(0, 50px);
}
.rv-xy-plot__axis--horizontal :global .rv-xy-plot__axis__title {
  transform: translate(0, 5em);
}
:global .rv-xy-plot__axis__tick__text {
  font-size: 0.5rem;
  font-family: 'Droid Sans', sans-serif;
  fill: #808080;
}
@media screen and (min-width: 700px) {
  :global .rv-xy-plot__axis__tick__text {
    font-size: 0.714285714285714em;
  }
}
@media screen and (min-width: 1200px) {
  :global .rv-xy-plot__axis__tick__text {
    font-size: 1em;
  }
}
:global .rv-hint {
  pointer-events: none;
}
:global .rv-hint--horizontalAlign-right :global .rv-hint--verticalAlign-top {
  transform: translate(-50%, 0.5em);
}
:global .rv-hint--horizontalAlign-left :global .rv-hint--verticalAlign-top {
  transform: translate(50%, 0.5em);
}
:global .rv-hint--horizontalAlign-right :global .rv-hint--verticalAlign-bottom {
  transform: translate(-50%, -0.571428571428571em);
}
:global .rv-hint--horizontalAlign-left :global .rv-hint--verticalAlign-bottom {
  transform: translate(50%, -0.571428571428571em);
}
:global .visualization--y-axis-label-rotated {
  font-size: 0.857142857142857em;
  color: #808080;
  position: absolute;
  top: 45%;
  left: 0;
  transform: translate(-35%, 0%) rotate(-90deg);
}
:global .visualization--y-axis-label-distance-to-graph {
  padding-bottom: 25px;
  padding-left: 25px;
}
@media screen and (min-width: 1200px) {
  :global .visualization--y-axis-label-distance-to-graph {
    padding-bottom: 50px;
    padding-left: 50px;
  }
}
:global .visualization--x-axis-label {
  font-size: 0.857142857142857em;
  color: #808080;
  position: absolute;
  bottom: 2.5em;
  left: 0;
  right: 0;
  text-align: center;
}
:global .visualization__axis,
:global .visualization__area-usage-tick {
  font-size: 0.857142857142857em;
  color: #808080;
}
:global .visualization__area-usage-tick {
  position: absolute;
  width: 13.333333333333334em;
  transform: translateY(-50%);
  left: 0;
}
:global .visualization__area-usage-tick--municipality {
  top: 5.416666666666667em;
}
:global .visualization__area-usage-tick--municipality-print {
  top: 2.083333333333333em;
}
:global .visualization__area-usage-tick--compare {
  top: 14.166666666666666em;
}
:global .visualization__area-usage-tick--compare-print {
  top: 7.5em;
}
:global .visualization__hint {
  font-size: 1rem;
  height: 2.857142857142857em;
  white-space: nowrap;
  line-height: 2.857142857142857em;
  padding: 0 0.857142857142857em;
  background: #f2f2f2;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 1.285714285714286em;
  transform: translateX(-50%);
  box-shadow: 0 0 2px rgba(0,0,0,0.4);
}
@supports (filter: drop-shadow(0 0 2px rgba(0,0,0,0.4))) {
  :global .visualization__hint {
    box-shadow: none;
    filter: drop-shadow(0 0 2px rgba(0,0,0,0.4));
  }
}
:global .visualization__hint::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.714285714285714em 0.607142857142857em 0 0.607142857142857em;
  border-color: #f2f2f2 transparent transparent transparent;
}
:global .visualization__hint--bar-chart {
  margin-bottom: 1.071428571428571em;
}
:global .visualization__hint__value {
  color: #000;
}
:global .visualization__hint--population {
  width: 250px;
  white-space: normal;
  height: auto;
  line-height: 1.5;
  padding-top: 1em;
  padding-bottom: 1em;
}
:global .visualization__hint__year {
  color: #ccc;
  margin-left: 1.428571428571429em;
}
:global .visualization__hint__percentage {
  color: #ccc;
  margin-left: 1.428571428571429em;
}
:global .poi {
  font-size: 1rem;
  background: #fff;
  position: relative;
  min-width: 12.5em;
  max-width: 19.642857142857142em;
  padding: 0.357142857142857em 0.714285714285714em;
  transform: translateX(-50%);
  filter: drop-shadow(0 0 1px rgba(0,0,0,0.2));
  border-radius: 2px;
}
:global .poi:before {
  content: ' ';
  width: 0;
  height: 0;
  border-top: 0.714285714285714em solid #fff;
  border-left: 0.714285714285714em solid transparent;
  border-right: 0.714285714285714em solid transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
:global .poi__type {
  color: #ccc;
}
:global .assumption-intro {
  font-style: italic;
  font-weight: bold;
}
:global .assumption-input {
  text-align: right;
  background: #f2f2f2;
}
:global .assumption-non-input {
  padding-right: 25px;
}
:global .table__hint {
  font-size: 0.8rem;
}
